<template>
  <div class="bg-white contact-us-container">
    <v-container fluid>
      <v-row align="center" justify="center" class="content-row">
        <v-col cols="12" md="5" lg="4">
          <v-img 
            :src="phone" 
            class="mb-2" 
            max-height="350" 
            max-width="100%" 
            alt="interview"
          />
        </v-col>
        <v-col cols="12" md="5" lg="4">
          <div class="form-card">
            <h2 class="text-center">CONTACT US!</h2>
            <p class="text-center mb-6">
              We can't solve your problem if you don't tell us about it!
            </p>
            <v-form>
              <v-text-field
                label="Your Name"
                outlined
                color="white"
                class="mb-4"
                style="background-color: white;"
              />
              <v-text-field
                label="Email"
                outlined
                color="white"
                class="mb-4"
                type="email"
                style="background-color: white;"
              />
              <v-textarea
                label="Message"
                outlined
                color="white"
                class="mb-4"
                style="background-color: white;"
              />
              <v-btn
                @click="sendMessage"
                color="blue darken-2"
                block
                size="large"
              >
                SEND
              </v-btn>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import phone from '@/assets/phone-contact.gif'
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      phone,
    };
  },
  methods: {
    sendMessage() {
      if (this.name && this.email && this.message) {
        alert("Message sent!");
      } else {
        alert("Please fill all fields.");
      }
    },
  },
};
</script>

<style scoped>
.contact-us-container { 
  background-color: #FFFFFFFF;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-row {
  gap: 40px; /* Creates space between the image and form */
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-card {
  background-color: white;
  padding: 24px;
  border-radius: 8px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 22px;
  font-weight: bold;
}

.send-button {
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
}
</style>
