<template>
  <v-navigation-drawer v-model="drawer">
    <v-tooltip text="Human Resources Information System" location="right">
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          lines="two"
          :prepend-avatar="require('@/assets/lcg-logo.webp')"
          title="HRIS"
          subtitle="Las Casas Gaming, Inc."
          title-class="bold-title"
        ></v-list-item>
      </template>
    </v-tooltip>

    <v-divider></v-divider>

    <v-list density="compact" nav>
      <v-list-item
        prepend-icon="mdi-view-dashboard-variant"
        title="Dashboard"
        value="dashboard"
        color="primary"
        :to="{ name: 'dashboard' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-office-building-outline"
        v-if="permissions.some((obj) => obj.permission_id === 10)"
        title="Companies"
        value="companies"
        color="primary"
        :to="{ name: 'companies' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-group"
        title="Departments"
        value="departments"
        color="primary"
        :to="{ name: 'departments' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-clock"
        title="Attendances"
        value="attendances"
        color="primary"
        :to="{ name: 'attendances' }"
      ></v-list-item>
      
      <v-list-item
        prepend-icon="mdi-calendar-arrow-right"
        title="Schedule"
        value="schedules"
        color="primary"
        :to="{ name: 'schedules' }"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-umbrella-beach-outline"
        title="Holidays"
        value="holidays"
        color="primary"
        :to="{ name: 'holidays' }"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-credit-card"
        v-if="permissions.some((obj) => obj.permission_id === 13)"
        title="Payrolls"
        value="payrolls"
        color="primary"
        :to="{ name: 'payrolls' }"
      ></v-list-item>

      <v-list-subheader class="text-uppercase mt-3">Users</v-list-subheader>
      <v-list-item
        prepend-icon="mdi-shield-account"
        title="Admins"
        value="admins"
        color="primary"
        :to="{ name: 'admins' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-account-tie"
        title="Employees"
        value="employees"
        color="primary"
        :to="{ name: 'employees' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-account"
        title="Applicants"
        value="applicants"
        color="primary"
        :to="{ name: 'applicants' }"
      ></v-list-item>

      <v-list-subheader class="text-uppercase mt-3">Careers</v-list-subheader>
      <v-list-item
        v-if="permissions.some((obj) => obj.permission_id === 4)"
        prepend-icon="mdi-briefcase"
        title="Jobs"
        value="jobs"
        color="primary"
        :to="{ name: 'adminJobs' }"
      ></v-list-item>
      <v-list-item
        v-if="permissions.some((obj) => obj.permission_id === 3)"
        prepend-icon="mdi-briefcase-account"
        title="Applications"
        value="applications"
        color="primary"
        :to="{ name: 'applications' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-briefcase-check"
        title="Qualifications"
        value="qualifications"
        color="primary"
        :to="{ name: 'qualifications' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-briefcase-clock"
        title="Responsibilities"
        value="responsibilities"
        color="primary"
        :to="{ name: 'responsibilities' }"
      ></v-list-item>

      <v-list-subheader class="text-uppercase mt-3">Settings</v-list-subheader>
      <v-list-item
        prepend-icon="mdi-shield-edit"
        v-if="permissions.some((obj) => obj.permission_id === 7)"
        title="Permissions"
        value="permissions"
        color="primary"
        :to="{ name: 'permissions' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-account-cog"
        title="Account"
        value="account"
        color="primary"
        :to="{ name: 'account' }"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-logout"
        title="Logout"
        value="logout"
        color="primary"
        @click="handleLogout"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar :elevation="2">
    <template v-slot:prepend>
      <v-app-bar-nav-icon
        :class="{ 'icon-animated': menuIconToggled }"
        @click="toggleDrawer"
      ></v-app-bar-nav-icon>
    </template>

    <v-spacer></v-spacer>

    <v-tooltip text="Switch Portal" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" icon :to="{ name: 'jobs' }">
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
  </v-app-bar>

  <LogoutDialog ref="logout" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LogoutDialog from "@/components/LogoutDialog";

export default {
  components: { LogoutDialog },

  data: () => ({
    drawer: null,
    menuIconToggled: false,
  }),

  computed: {
    ...mapGetters("auth", ["user", "permissions"]),
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    async handleLogout() {
      this.$refs.logout.show();
      await this.logout();
      this.$router.push({ name: "login" });
      this.$refs.logout.hide();
    },

    toggleDrawer() {
      this.drawer = !this.drawer;
      this.menuIconToggled = true;
      setTimeout(() => {
        this.menuIconToggled = false;
      }, 300);
    },

  },
};
</script>

<style scoped>
.icon-animated {
  animation: rotate-icon 0.3s ease;
}

@keyframes rotate-icon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
</style>
