<template>
  <div class="login-container main_bg">
    <v-card
      max-width="400"
      :disabled="social || registerLoading"
      :loading="social"
    >
      <v-card-text>
        <h1 class="mb-8">Register</h1>

        <v-form ref="form" @submit.prevent="register" validate-on="submit">
          <v-text-field
            ref="email"
            v-model="form.email"
            label="Email"
            color="primary"
            variant="outlined"
            density="comfortable"
            :rules="[
              rules.required,
              rules.validEmail,
              emailExist,
              rules.maximum(100),
            ]"
            class="mb-5"
          ></v-text-field>
          <v-text-field
            v-model="form.password"
            label="Password"
            color="primary"
            variant="outlined"
            density="comfortable"
            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append-inner="showPassword = !showPassword"
            autocomplete="current-password"
            :rules="[rules.required, rules.minimum(6), rules.maximum(100)]"
          ></v-text-field>
          <div class="text-caption text-center text-grey mb-5">
            By clicking Register button, you agree to the HR
            <a class="text-blue" href="#" target="_blank"> Terms of Use </a>
            and
            <a class="text-blue" href="#" target="_blank"> Privacy Policy </a>.
          </div>
          <v-btn
            type="submit"
            color="primary"
            class="mb-5"
            :loading="registerLoading"
            block
            >Register</v-btn
          >
        </v-form>

        <div class="hr-or mb-5">or</div>

        <div class="mb-5">
          <!-- @click="google" -->
          <v-btn variant="outlined" block class="text-none mb-2">
            <img
              width="25"
              height="25"
              src="https://img.icons8.com/color/48/google-logo.png"
              class="mr-1"
            />
            Continue with Google
          </v-btn>
          <v-btn variant="outlined" block class="text-none">
            <img
              width="25"
              height="25"
              src="https://img.icons8.com/color/48/facebook-new.png"
              class="mr-1"
            />
            Continue with Facebook
          </v-btn>
        </div>
        <div class="text-center">
          <p>Already have an account?</p>
          <v-btn
            color="primary"
            variant="text"
            :to="{ name: 'login' }"
            density="compact"
          >
            Login
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>

  <v-dialog v-model="successDialog" width="auto" persistent>
    <v-card
      max-width="400"
      prepend-icon="mdi-check-circle"
      text="We sent you an email to verify your account."
      title="Registered Successfully"
    >
      <template v-slot:prepend>
        <v-icon color="success" size="x-large"></v-icon>
      </template>

      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          variant="tonal"
          text="Ok"
          :to="{ name: 'login' }"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules";
import { isObjectEmpty } from "@/helpers/form";
import { mapState } from "vuex";

export default {
  data: () => ({
    rules: formRules,
    form: {
      email: "",
      password: "",
    },
    showPassword: false,
    emailExistText: null,
    successDialog: false,
    apiUrl: process.env.VUE_APP_API_LINK,
    social: false,
  }),

  mounted() {
    setTimeout(() => {
      this.$refs.email.focus();
    }, 100);
  },

  computed: {
    ...mapState("auth", ["registerLoading", "errors"]),
  },

  methods: {
    google() {
      this.social = true;
      window.location.href = this.apiUrl + "auth/google/redirect";
    },

    emailExist(val) {
      if (val == this.emailExistText) {
        return `"${val}" already exist`;
      } else {
        return true;
      }
    },

    async register() {
      const formRef = await this.$refs.form.validate();

      if (formRef.valid && !this.registerLoading) {
        const res = await this.$store.dispatch("auth/register", this.form);

        if (isObjectEmpty(this.errors)) {
          this.$refs.form.reset();

          if (res && res.status === 201) {
            this.successDialog = true;
          }
        } else {
          this.setErrors();
        }
      }
    },

    setErrors() {
      let errorFocus = "";

      Object.entries(this.errors).forEach(([key, value]) => {
        if (value) {
          if (!errorFocus) {
            errorFocus = key;
          }

          if (key == "email") {
            this.emailExistText = this.form.email;
          }

          this.$refs.form.validate();
          this.$refs[errorFocus].focus();
        }
      });
    },
  },
};
</script>

<style scoped>
.main_bg {
  background-image: url("@/assets/pattern-randomized.svg");
  background-size: cover;
  background-position: center;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.v-card {
  width: 400px;
  padding: 10px;
}

.hr-or {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: 8px 0px;
}

.hr-or:before,
.hr-or:after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}
</style>