<template>
  <v-app-bar app dense style="background: linear-gradient(50.5deg, #1C1C1CFF 27.9%, #404040FF 84.2%);">
    <v-app-bar-nav-icon color="white" v-if="isMobileView" @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-toolbar-title>
      <v-img :src="require('@/assets/lcgi-logo.png')" max-width="250"></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn :to="{ name: 'faceRecognition' }" variant="text" rounded color="white" class="mx-3" v-if="isMobileView">
      <v-icon>mdi-face-recognition</v-icon>
    </v-btn>

    <template v-if="!isMobileView">
      <v-toolbar-items>
        <v-btn
          v-for="(item, index) in toolbarItems"
          :key="index"
          :to="{ name: item.to }"
          :prepend-icon="item.icon"
          stacked
          variant="text"
          size="small"
          color="white"
        >
          {{ item.name }}
        </v-btn>
      </v-toolbar-items>

      <v-divider class="border-opacity-25" vertical></v-divider>

      <div v-if="!authenticated" class="d-flex ga-2 mx-3">
        <v-btn :to="{ name: 'login' }" variant="outlined" rounded color="white"> Login </v-btn>
        <v-btn :to="{ name: 'register' }" variant="text" rounded color="white"> Register </v-btn>
      </div>

      <v-menu width="256" rounded v-else>
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" class="mx-3">
            <v-avatar color="primary"><span>{{ initial }}</span></v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="primary" size="large">
                  <span class="text-h5">{{ initial }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ fullName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'profile' }">
              <v-btn size="small" color="primary" block variant="tonal"> View Profile </v-btn>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-list>
            <v-list-item :to="{ name: 'jobApplications' }" color="primary">
              <v-icon icon="mdi-briefcase-account-outline"></v-icon>
              <v-list-item-title> Job Applications </v-list-item-title>
            </v-list-item>

            <div v-if="isEmployee">
              <v-list-item v-for="(item, i) in menuItems" :key="i" :to="{ name: item.to }" color="primary">
                <v-icon :icon="item.icon"></v-icon>
                <v-list-item-title> {{ item.title }} </v-list-item-title>
              </v-list-item>
            </div>

            <v-divider></v-divider>

            <v-list-item class="account-link text-red-darken-1 mt-1" @click="handleLogout">
              <v-icon icon="mdi-logout-variant"></v-icon>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-divider class="border-opacity-25" vertical></v-divider>

      <div class="px-2">
        <v-btn :to="{ name: 'faceRecognition' }" variant="text" rounded color="white">
          <v-icon>mdi-face-recognition</v-icon>
        </v-btn>
      </div>

      <v-tooltip text="Switch Portal" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" icon :to="{ name: 'dashboard' }" v-if="isAdmin">
            <v-icon color="white">mdi-swap-horizontal</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
  </v-app-bar>

  <!-- Mobile Drawer -->
  <v-navigation-drawer v-model="drawer" app temporary>
    <v-list>
      <v-list-item v-for="(item, index) in toolbarItems" :key="index" :to="{ name: item.to }">
        <!-- <v-icon left>{{ item.icon }}</v-icon> -->
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list v-if="authenticated">
      <v-list-item :to="{ name: 'profile' }">
        <!-- <v-icon left>mdi-account</v-icon> -->
        <v-list-item-title> View Profile </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isEmployee" v-for="(item, i) in menuItems" :key="i" :to="{ name: item.to }">
        <!-- <v-icon left>{{ item.icon }}</v-icon> -->
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAdmin" :to="{ name: 'dashboard' }">
        <!-- <v-icon left>mdi-swap-horizontal</v-icon> -->
        <v-list-item-title> Switch Portal </v-list-item-title>
      </v-list-item>

      <v-list-item class="text-red-darken-1" @click="handleLogout">
        <!-- <v-icon left>mdi-logout-variant</v-icon> -->
        <v-list-item-title> Logout </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list v-else>
      <v-list-item :to="{ name: 'login' }">
        <!-- <v-icon left>mdi-login</v-icon> -->
        <v-list-item-title> Login </v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'register' }">
        <!-- <v-icon left>mdi-account-plus</v-icon> -->
        <v-list-item-title> Register </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <LogoutDialog ref="logout" />
</template>

<script>
import { useDisplay } from "vuetify";
import { mapActions, mapGetters } from "vuex";
import LogoutDialog from "@/components/LogoutDialog";

export default {
  components: { LogoutDialog },

  data: () => ({
    drawer: false,
    display: useDisplay(),
    toolbarItems: [
      { name: "Jobs", to: "jobs", icon: "mdi-briefcase-variant" },
      { name: "About Us", to: "aboutUs", icon: "mdi-information" },
      { name: "FAQ", to: "faq", icon: "mdi-frequently-asked-questions" },
      { name: "Contact", to: "contact", icon: "mdi-phone" },
    ],
    menuItems: [
      { title: "Attendance", to: "attendance", icon: "mdi-clock-outline" },
      { title: "My Leaves", to: "myLeaves", icon: "mdi-file-document-outline" },
      { title: "My Payroll", to: "myPayroll", icon: "mdi-credit-card-outline" },
    ],
  }),

  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),

    isMobileView() {
      return this.display.smAndDown;
    },

    isAdmin() {
      return this.authenticated && this.user.role_id === 1;
    },

    isEmployee() {
      return this.authenticated && this.user.role_id !== 3;
    },

    initial() {
      return this.user?.first_name?.charAt(0) || this.user?.email?.charAt(0);
    },

    fullName() {
      return this.user ? `${this.user.first_name} ${this.user.last_name}` : "";
    },
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    async handleLogout() {
      this.$refs.logout.show();
      await this.logout();
      this.$router.push({ name: "jobs" });
      this.$refs.logout.hide();
    },
  },
};
</script>

  <style scoped>
  .menu-card {
    font-family: "Poppins", sans-serif !important;
  }

  .account-link:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
  </style>